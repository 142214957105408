import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-30.000000, -20.000000)">
      <g transform="translate(-170.7 -200.7)">
        <path
          d="M245,252.2l-4,15.9,2.3.2a15.7,15.7,0,0,0,3.2-.5l-.3,1.5a14.5,14.5,0,0,1-3.1.4l-3.4-.2-3.3-.2a24.4,24.4,0,0,0-5.6.7l.5-1.7a13.3,13.3,0,0,0,4.2-1.6,8.6,8.6,0,0,0,2.3-4.6l4.2-16.7a32,32,0,0,0,.8-3.8.9.9,0,0,0-.4-.8l-1.4-.2.3-1.4a37,37,0,0,0,7.3-1.1,28.2,28.2,0,0,0-1,3.3l-2.2,8.8,2.4.3a8.6,8.6,0,0,0,6.4-2.6,8.3,8.3,0,0,0,2.7-6.2,5.5,5.5,0,0,0-2.2-4.6,8.6,8.6,0,0,0-5.8-1.8,19.8,19.8,0,0,0-9.1,2.3,17.5,17.5,0,0,0-6.8,5.6,12.6,12.6,0,0,0-2.2,7.1,6.5,6.5,0,0,0,2.3,4.8,4.3,4.3,0,0,1,1.4,1.4,1.7,1.7,0,0,1,.3,1,1.6,1.6,0,0,1-.5,1.1,1.5,1.5,0,0,1-1.1.4c-1.1,0-2-.6-2.9-1.9a10.3,10.3,0,0,1-1.6-6,15.7,15.7,0,0,1,2.5-8.6,20.3,20.3,0,0,1,7.7-6.6,24.6,24.6,0,0,1,10.9-2.6,17.6,17.6,0,0,1,8.8,2,6.5,6.5,0,0,1,3.4,6,9.5,9.5,0,0,1-3.1,6.8,15.4,15.4,0,0,1-8.7,4.1l1.8,3.7,1.9,5.4c2,6,4.1,10.2,6.2,12.7a6.9,6.9,0,0,0,5.2,2.7,3.6,3.6,0,0,0,3.7-2.6,5.3,5.3,0,0,1,.9-2.1,1.9,1.9,0,0,1,1.3-.5,1.7,1.7,0,0,1,1.7,1.7,4.6,4.6,0,0,1-2.1,3.6,7.9,7.9,0,0,1-5.2,1.6A11.4,11.4,0,0,1,260,277a16.3,16.3,0,0,1-5.1-4.6,32.3,32.3,0,0,1-4.4-7.6c-.6-1.5-1.5-4.3-2.7-8.3-.7-2.4-1.1-3.8-1.3-4.3H245Z"
          fill="currentColor"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
