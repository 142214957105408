import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(-198.7 -208.7)">
        <path
          d = "M245,252.2l-4,15.9,2.3.2a15.7,15.7,0,0,0,3.2-.5l-.3,1.5a14.5,14.5,0,0,1-3.1.4l-3.4-.2-3.3-.2a24.4,24.4,0,0,0-5.6.7l.5-1.7a13.3,13.3,0,0,0,4.2-1.6,8.6,8.6,0,0,0,2.3-4.6l4.2-16.7a32,32,0,0,0,.8-3.8.9.9,0,0,0-.4-.8l-1.4-.2.3-1.4a37,37,0,0,0,7.3-1.1,28.2,28.2,0,0,0-1,3.3l-2.2,8.8,2.4.3a8.6,8.6,0,0,0,6.4-2.6,8.3,8.3,0,0,0,2.7-6.2,5.5,5.5,0,0,0-2.2-4.6,8.6,8.6,0,0,0-5.8-1.8,19.8,19.8,0,0,0-9.1,2.3,17.5,17.5,0,0,0-6.8,5.6,12.6,12.6,0,0,0-2.2,7.1,6.5,6.5,0,0,0,2.3,4.8,4.3,4.3,0,0,1,1.4,1.4,1.7,1.7,0,0,1,.3,1,1.6,1.6,0,0,1-.5,1.1,1.5,1.5,0,0,1-1.1.4c-1.1,0-2-.6-2.9-1.9a10.3,10.3,0,0,1-1.6-6,15.7,15.7,0,0,1,2.5-8.6,20.3,20.3,0,0,1,7.7-6.6,24.6,24.6,0,0,1,10.9-2.6,17.6,17.6,0,0,1,8.8,2,6.5,6.5,0,0,1,3.4,6,9.5,9.5,0,0,1-3.1,6.8,15.4,15.4,0,0,1-8.7,4.1l1.8,3.7,1.9,5.4c2,6,4.1,10.2,6.2,12.7a6.9,6.9,0,0,0,5.2,2.7,3.6,3.6,0,0,0,3.7-2.6,5.3,5.3,0,0,1,.9-2.1,1.9,1.9,0,0,1,1.3-.5,1.7,1.7,0,0,1,1.7,1.7,4.6,4.6,0,0,1-2.1,3.6,7.9,7.9,0,0,1-5.2,1.6A11.4,11.4,0,0,1,260,277a16.3,16.3,0,0,1-5.1-4.6,32.3,32.3,0,0,1-4.4-7.6c-.6-1.5-1.5-4.3-2.7-8.3-.7-2.4-1.1-3.8-1.3-4.3H245Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

{/* <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.5 167.5"><defs><style>.cls-1{fill:none;stroke:#f40029;stroke-miterlimit:10;stroke-width:5px;}</style></defs><path class="cls-1" d="M183.7,121.3l27-2.3c58.6-19.3,59.4,45.5,59.4,45.5-13.1,54.7-64,37.8-64,37.8l75.6,75.6H255.9l-49-49v48.9H183.7V121.3Zm20.9,17v47s36.5,3.1,51.4-18.5c0,0,.2-31.6-13.6-32.4Z" transform="translate(-181.2 -112.9)"/></svg> */}

{/* <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.2 101.8"><polygon points="89.2 75.5 45.6 101.8 1 77.3 0 26.4 43.6 0 88.2 24.6 89.2 75.5" style="fill:#f44b00"/><text transform="translate(26.7 68.2) scale(1.21 1)" style="font-size:54.353248596191406px;fill:#fff;font-family:MyriadPro-Regular, Myriad Pro">R</text></svg> */}
export default IconLoader;
